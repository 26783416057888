
.logoWrapper {
    margin-bottom: 16px;
    margin-left: 30px;
    width: 190px;
    z-index: 9;
    font-size: 22px;

    > a {
        width: 130px;
        color: #fff;
    }
}
