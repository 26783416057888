.auth__page {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #d2d6de;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.auth__modal {
    background-color: #fff;
    border-radius: 6px;
    min-width: 300px;
    max-width: 450px;
    width: 100%;
    margin: 10px;
    padding: 50px 30px;
}

.auth__submit-button {
    width: 100%;
    margin: 10px 0;
}


.auth__logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    font-size: 30px;

    > img {
        height: 55px;
    }
}

.auth__top-info {
    text-align: center;
    font-size: 18px;
}
